import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MUIDrawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import { useNavigate, useMatch } from 'react-router-dom';
import * as pathsHelpers from '@/fleet/paths/helpers';

import logo from '../../logo.png';

const drawerWidth = 240;

export const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));

interface ICarrier {
  carrierUuid: string;
}

const Drawer = ({ carrierUuid }: ICarrier) => {
  const navigate = useNavigate();

  const menuList = [
    {
      text: 'Dashboard',
      path: pathsHelpers.getPathDashboard(carrierUuid),
    },
    {
      text: 'Manage Drivers',
      path: pathsHelpers.getPathDrivers(carrierUuid),
    },
    {
      text: 'Stations',
      path: pathsHelpers.getPathStations(carrierUuid),
    },
    {
      text: 'Reports',
      path: pathsHelpers.getPathReports(carrierUuid),
    },
    {
      text: 'Members',
      path: pathsHelpers.getPathMembers(carrierUuid),
    },
    {
      text: 'Tractors',
      path: pathsHelpers.getPathTractors(carrierUuid),
    },
    {
      text: 'Payment History',
      path: pathsHelpers.getPathPaymentHistory(carrierUuid),
    },
    {
      text: 'One Time Limit',
      path: pathsHelpers.getPathOneTimeLimit(carrierUuid),
    },
    {
      text: 'Settings',
      path: pathsHelpers.getPathSettings(carrierUuid),
    },
  ];

  return (
    <MUIDrawer
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: drawerWidth,
          boxSizing: 'border-box',
        },
      }}
      variant="persistent"
      anchor="left"
      open
    >
      <Box
        component="img"
        sx={{
          height: 63,
          width: 63,
          marginTop: '35px',
          marginLeft: '32px',
          marginBottom: '20px',
        }}
        alt="Onramp logo"
        src={logo}
      />
      <List>
        {menuList.map((item) => (
          <ListItem
            key={item.text}
            onClick={() => navigate(item.path)}
            disablePadding
          >
            <ListItemButton selected={Boolean(useMatch(item.path))}>
              <ListItemText primary={item.text} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </MUIDrawer>
  );
};

export default Drawer;
